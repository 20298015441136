import React, { useState } from "react"
import PageRoot from "../../components/PageRoot"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import ContactForm from "../../components/forms/ContactForm"
import { Spin } from "antd"
import { translatePath, uiHandleError } from "../../utils"
import useCommonQueries from "../../hooks/useCommonQueries"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"

const HelpPage = () => {
  const [sending, setSending] = useState(false)
  const { sendEmail } = useCommonQueries()

  const { t } = useTranslation()
  const handleSubmit = async values => {
    setSending(true)
    try {
      await sendEmail(values)
      navigate(translatePath("/help/success"), { replace: true })
    } catch (error) {
      uiHandleError({ error })
    }
    setSending(false)
  }
  return (
    <PageRoot title={t("label:supportAndAssistance")} showTitle>
      <ContainerWithPadding size={"small"}>
        <p className="support-desc">
          {t("message:helpMessage1")} <span> {t("message:helpMessage2")} </span>
          {t("message:helpMessage3")}
        </p>
        <Spin spinning={sending}>
          <ContactForm onFinish={handleSubmit} />
        </Spin>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default HelpPage
